var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { "max-width": "1100px" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "mb-5",
          attrs: { flat: "", dark: "", color: "primary" }
        },
        [
          _c(
            "v-btn",
            { attrs: { icon: "", dark: "" }, on: { click: _vm.fecharDialog } },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _c("v-toolbar-title", [_vm._v("Reapresentação de titulo")])
        ],
        1
      ),
      _c("DetalheTituloReapresentacao", {
        attrs: { tituloParaReapresentacao: _vm.titulo },
        on: {
          fecharModal: _vm.fecharDialog,
          reapresentarTitulo: _vm.reapresentarTitulo
        }
      }),
      _vm.dialogIrregularidades
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "800px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogIrregularidades,
                callback: function($$v) {
                  _vm.dialogIrregularidades = $$v
                },
                expression: "dialogIrregularidades"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "ma-2", attrs: { large: "" } },
                        [_vm._v(" mdi-information ")]
                      ),
                      _vm._v("Houve irregularidades no título ")
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            _vm._l(_vm.irregularidades, function(
                              irregularidade,
                              index
                            ) {
                              return _c(
                                "v-list-item",
                                { key: index },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " + _vm._s(irregularidade) + " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-end my-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text d-flex justify-center pl-5",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.dialogIrregularidades = false
                            }
                          }
                        },
                        [
                          _vm._v(" Fechar "),
                          _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v(" mdi-chevron-right ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.snackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbar,
              corSnackbar: _vm.colorSnackbar,
              mensagemSnackbar: _vm.mensagemSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }